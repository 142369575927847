import React from 'react';
import '../css/ContactScreen.css';

const ContactScreen = () => {
	return (
		<div className='spacer layer1'>
			<div className='contact-container'>
				<div className='contact-title'>
					<h1>Contact </h1>
					<img
						src='https://i.ibb.co/DgdwSsy/Jahk-R-purple.png'
						alt='JahkR Logo'
					></img>
				</div>
				<div className='contact-details'>
					<div className='contact-left'>
						<div className='contact-phone'>
							<h3>Phone:</h3>
							<a href='https://wa.me/254717637732'>254717637732</a>
						</div>
						<div className='contact-email'>
							<h3>Email:</h3>
							<span>JahkRCode@gmail.com</span>
						</div>
					</div>
					<div className='contact-right'>
						<h2>Social Media</h2>
						<div className='contact-sm-icons'>
							<a className='float-in' href='https://instagram.com/jahkrcode'>
								<i className='fa fa-instagram'></i>{' '}
							</a>
							<a className='float-out' href='https://linkedin.com/in/jahkrcode'>
								<i className='fa fa-linkedin'></i>{' '}
							</a>
							<a className='float-in' href='https://github.com/jahkrcode'>
								<i className='fa fa-github'></i>
							</a>
							<a className='float-out' href='https://wa.me/254717637732'>
								<i className='fa fa-whatsapp'></i>{' '}
							</a>
							<a className='float-in' href='https://www.youtube.com/@jahkrcode'>
								<i className='fa fa-youtube'></i>{' '}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactScreen;
