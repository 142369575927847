import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import AboutScreen from './screens/AboutScreen';
import ContactScreen from './screens/ContactScreen';
import ServicesScreen from './screens/ServicesScreen';
import PortfolioScreen from './screens/PortfolioScreen';
import './App.css';

function App() {
	return (
		<BrowserRouter>
			<div className='container'>
				<Header />
				<Routes>
					<Route
						path='/'
						element={<HomeScreen />}
					/>
					<Route
						path='/about'
						exact
						element={<AboutScreen />}
					/>
					<Route
						path='/contact'
						exact
						element={<ContactScreen />}
					/>
					<Route
						path='/services'
						exact
						element={<ServicesScreen />}
					/>
					<Route
						path='/portfolio'
						exact
						element={<PortfolioScreen />}
					/>
				</Routes>
			</div>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
