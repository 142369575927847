import React from 'react';
import '../css/PortfolioScreen.css';

const PortfolioScreen = () => {
	return (
		<div className='spacer layer1'>
			<div className='portfolio-container'>
				<div className='title'>
					<img
						src='https://i.ibb.co/DgdwSsy/Jahk-R-purple.png'
						alt='JahkR Logo'
					></img>
					<h1>Portfolio </h1>
				</div>
				<div className='cards'>
					<div className='card float-in'>
						<div className='image-container'>
							<img
								src='https://i.ibb.co/J3MLs8J/pillpackhealthcare-website.png'
								alt='pill pack health care'
							></img>
						</div>
						<div className='details'>
							<h2>Pill Pack Healthcare Ltd</h2>
							<p>
								Pill Pack Healthcare Limited is a licensed importer, supplier
								and distributor of pharmaceutical products. They provide a huge
								selection of oncology items. They also service Retail
								Pharmacies, Wholesale Distributors, and Institutional Providers
								such as Hospitals, Health Systems, Integrated Delivery Networks,
								and Long-Term Care Providers.
							</p>
						</div>
					</div>
					<div className='card float-out'>
						<div className='image-container'>
							<img
								src='https://i.ibb.co/ryyxLBW/kairobins-website.png'
								alt='KaiRobin liquor alcohol store'
							></img>
						</div>
						<div className='details'>
							<h2>KaiRobin's Liquor Store</h2>
							<p>
								KaiRobin's Liquor Store offer a wide selection of wines from all
								over the world, as well as a variety of liquors and other
								alcoholic beverages. You can browse all of our options for red
								wine, white wine, whiskeys, gins, spirits, vapes, and much more.
								Find the perfect item for yourself or someone special. Whether
								you're looking for a special occasion cognac, Spirit, or just a
								casual beer, we have something for everyone.
							</p>
						</div>
					</div>
					<div className='card float-in'>
						<div className='image-container'>
							<img
								src='https://i.ibb.co/Lk1B9QM/spanishjournal-website.png'
								alt='spanish journal network'
							></img>
						</div>
						<div className='details'>
							<h2>Spanish Journal Network</h2>
							<p>
								The Spanish Journal, Wisconsin’s largest leading weekly Hispanic
								publication with a circulation that reaches the Hispanic
								communities in Milwaukee, Green Bay, Kenosha, Racine, Waukesha
								and their surrounding suburbs.
							</p>
						</div>
					</div>
					<div className='card float-out'>
						<div className='image-container'>
							<img
								src='https://i.ibb.co/58B1MP7/safires-website.png'
								alt='web mobile development programming'
							></img>
						</div>
						<div className='details'>
							<h2>S.A.F.I.R.E.S.</h2>
							<p>
								Sunshine And Freedom Innovative Researching & Engineering
								Services is dedicated to address the growing need of technology
								amongst urban business owners. We develop mobile applications on
								the Apple and Google Play Stores for entrepreneurs and connect
								their business needs with the ever growing tech industry and
								customer demand.
							</p>
						</div>
					</div>
					<div className='card float-in'>
						<div className='image-container'>
							<img
								src='https://i.ibb.co/xsVLjKD/jahkrmusic-website.png'
								alt='JahkR music afrobeats'
							></img>
						</div>
						<div className='details'>
							<h2>JahkR Music</h2>
							<p>
								JahkR always had an affinity for awesome and dramatic chord
								progressions. Sound is the universal language of life, and as
								humans we have the ability to cultivate this power and use it at
								will. It is now time to show the world and maybe inspire others
								to find something they love and grow with it. I hope you enjoy
								it. Live Free!
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PortfolioScreen;
