import React from 'react';
import '../css/WhatsAppButton.css';
const WhatsAppButton = () => {
	return (
		<div className='whatsapp_container'>
			<a
				href='https://wa.me/254717637732'
				className='whatsapp_float float-out'
				target='_blank'
				rel='noopener noreferrer'
			>
				<i className='fa fa-whatsapp whatsapp-icon'></i>
			</a>
		</div>
	);
};

export default WhatsAppButton;
