import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../css/HomeScreen.css';

const HomeScreen = () => {
	const isEmptyObject = (obj) => {
		var name;
		for (name in obj) {
			if (obj.hasOwnProperty(name)) {
				return false;
			}
		}
		return true;
	};
	/***** 3D Box Effect *****/
	let boxesContainer = document.getElementById('boxes');
	const createBoxes = useCallback(() => {
		if (!isEmptyObject(boxesContainer)) {
			for (let i = 0; i < 4; i++) {
				for (let j = 0; j < 4; j++) {
					const box = document.createElement('div');
					box.classList.add('box');
					box.style.backgroundPosition = `${-j * 125}px ${-i * 125}px`;
					boxesContainer.appendChild(box);
				}
			}
		}
	}, [boxesContainer]);
	/***** Scale number to another *****/
	const scale = (num, in_min, in_max, out_min, out_max) => {
		return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
	};
	useEffect(() => {
		if (isEmptyObject(boxesContainer)) {
			boxesContainer = document.getElementById('boxes');
		} else {
			console.log(`***** CREATE BOXES: ${boxesContainer.firstChild}`);
			createBoxes();
		}
		const hiddenElements = document.querySelectorAll('.hidden');
		const loadTextContainer = document.querySelector('.loading-text');
		const loadText = document.querySelector('.loading-text h1');
		const loadImg = document.querySelector('.loading-text img');
		const homeContent = document.querySelector('.home-content');
		const textEl = document.querySelector('.hero-heading h1');
		const text = 'codes for YOU...';

		let load = 0;
		let idx = 1;
		let speed = 275;

		/***** Fade-In Scroll Animation Effect *****/
		const appearOptions = {
			threshold: 0.7,
		};
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add('show');
				} else {
					entry.target.classList.remove('show');
				}
			});
		}, appearOptions);
		hiddenElements.forEach((el) => observer.observe(el));

		/***** Blur Effect *****/
		const blurring = () => {
			load++;
			if (load > 99) {
				clearInterval(interval);
				loadTextContainer.style.zIndex = -2;
				loadText.style.zIndex = -2;
				loadImg.style.zIndex = -2;
			}
			loadText.innerText = `${load}%`;
			loadTextContainer.style.opacity = scale(load, 0, 100, 1, 0);
			loadText.style.opacity = scale(load, 0, 100, 1, 0);
			loadImg.style.opacity = scale(load, 0, 100, 1, 0);
			homeContent.style.filter = `blur(${scale(load, 0, 100, 30, 0)}px)`;
		};
		let interval = setInterval(blurring, 30);

		/***** Auto Write Text Effect *****/
		const writeText = () => {
			textEl.innerText = text.slice(0, idx);
			idx++;
			if (idx > text.length) {
				idx = 1;
			}
			setTimeout(writeText, speed);
		};
		//writeText();
	}, [boxesContainer]);

	const boxAnimation = () => {
		boxesContainer.classList.toggle('big');
	};
	return (
		<div className='spacer layer1'>
			<div className='loading-text'>
				<img
					src='https://i.ibb.co/DgdwSsy/Jahk-R-purple.png'
					alt='JahkR Logo'
				></img>
				<h1>0%</h1>
			</div>
			<div className='home-content'>
				<div className='hero-container '>
					<div className='hero-text hidden'>
						<div className='hero-heading hidden'>
							<img
								src='https://i.ibb.co/DgdwSsy/Jahk-R-purple.png'
								alt='JahkR Logo'
							></img>
							<h1>codes for YOU!!!!</h1>
						</div>
						<p>
							Welcome to JahkRCode! We build websites and automations for local
							start ups to help make them more productive. Our services bridge
							the gap between the global markets on the web and your business.
							With your goals in mind, we engineer a technical solution for your
							business needs that will increase engagement with your clients.
						</p>
						<div className='hero-call-to-action'>
							<a href='/contact'>Get Started</a>

							<span>Let's bring your vision to life! </span>
						</div>
					</div>
					<div className='hero-image'>
						<div id='boxes' className='boxes big' onClick={boxAnimation}></div>
					</div>
				</div>
				<div className='content-containers'>
					<div className='content-container right hidden'>
						<div className='content-image right float-in hidden '>
							<img
								src='https://i.ibb.co/3sKM3Hp/laptop-coding.jpg'
								alt='JahkR design development'
							></img>
						</div>
						<div className='content-text'>
							<h1>Our Design & Development Process</h1>
							<p>
								Achieve your business goals with beautifully designed and
								developed web applications, created with the latest
								technologies. No need to select one mobile platform over the
								other any more. Our web applications are mobile responsive for
								any device size!
							</p>
							<div className='call-to-action'>
								<span>Learn more about JahkRCode! </span>
								<Link to='/about'>
									<h3>About</h3>
								</Link>
							</div>
						</div>
					</div>
					<div className='content-container left hidden'>
						<div className='content-text'>
							<h1>We Build Web Apps For...</h1>
							<div className='content-industry'>
								<ul>
									<li>
										<p>Healthcare</p>
									</li>
									<li>
										<p>News & Events</p>
									</li>
									<li>
										<p>Education</p>
									</li>
								</ul>
								<ul>
									<li>
										<p>Food & Drinks</p>
									</li>
									<li>
										<p>Real Estate</p>
									</li>
									<li>
										<p>Hospitality</p>
									</li>
								</ul>
								<ul>
									<li>
										<p>Sports & Fitness</p>
									</li>
									<li>
										<p>Hair & Beauty</p>
									</li>
									<li>
										<p>YOU!!</p>
									</li>
								</ul>
							</div>
							<div className='call-to-action'>
								<Link to='/services'>
									<h3>Services</h3>
								</Link>
								<span>View our services! </span>
							</div>
						</div>
						<div className='content-image float-out'>
							<img
								src='https://i.ibb.co/rkwxxgK/black-hands-shake.jpg'
								alt='web development services'
							></img>
						</div>
					</div>
					<div className='content-container right hidden'>
						<div className='content-image float-in hidden '>
							<img
								src='https://i.ibb.co/D9SVpjk/growth.jpg'
								alt='JahkR affordable web development'
							></img>
						</div>
						<div className='content-text'>
							<h1>Affordable & Efficient</h1>
							<p>
								We build tools to aid in your business at an affordable cost to
								any budget. Our websites are completely custom built from
								scratch with no templates or added plugins that can drive up the
								cost. Our applications are developed with speed, efficiency, and
								security in mind for a reliable and scalable tool that grows
								with your business demands.
							</p>
							<div className='call-to-action'>
								<span>See for yourself! </span>
								<Link to='/portfolio'>
									<h3>Portfolio</h3>
								</Link>
							</div>
						</div>
					</div>
					<div className='content-container partners '>
						<div className='content-text'>
							<h1>PARTNERS</h1>
						</div>
						<div className='partner-images '>
							<div className='partner-image float-in'>
								<img
									src='https://i.ibb.co/ZLtj5wX/pillpackhealthcare-logo.png'
									alt='pill pack health care'
								></img>
							</div>
							<div className='partner-image float-out'>
								<img
									src='https://i.ibb.co/hKMpKZs/Spanishjournal-logo.png'
									alt='spanish journal network'
								></img>
							</div>
							<div className='partner-image float-in'>
								<img
									src='https://i.ibb.co/gwhV9dJ/kairobin-logo.png'
									alt='KaiRobin liquor store alcohol'
								></img>
							</div>
							<div className='partner-image float-out'>
								<img
									src='https://i.ibb.co/0rGLHn5/safires-logo.webp'
									alt='web mobile development android ios'
								></img>
							</div>
							<div className='partner-image float-in'>
								<img
									src='https://i.ibb.co/c62rnB4/c4dc-logo.png'
									alt='coding culture programming'
								></img>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeScreen;
