import React from 'react';

const Header = () => {
	const openHandler = () => {
		const container = document.querySelector('.container');
		container.classList.add('show-nav');
	};
	const closeHandler = () => {
		const container = document.querySelector('.container');
		container.classList.remove('show-nav');
	};
	return (
		<div className='circle-container'>
			<div className='circle'>
				<button onClick={closeHandler} id='close'>
					<i className='fa fa-times'></i>
				</button>
				<button onClick={openHandler} id='open'>
					<i className='fa fa-bars'></i>
				</button>
			</div>
		</div>
	);
};

export default Header;
