import React from 'react';
import '../css/AboutScreen.css';

const AboutScreen = () => {
	return (
		<div className='spacer layer1'>
			<div className='about-container'>
				<div className='about-title'>
					<h1>About </h1>
					<img
						src='https://i.ibb.co/DgdwSsy/Jahk-R-purple.png'
						alt='JahkR Logo'
					></img>
				</div>
				<div className='about-image'>
					<img
						src='https://i.ibb.co/mq7D6ZH/jahkr-welcome.png'
						alt='JahkR Code'
					></img>
				</div>
				<div className='about-text float-in'>
					<p>
						Thank you for visiting JahkR Code! Software engineering is our
						greatest passion and with over a decade of experience in the field,
						we aim to make quality tools that are visually appealing and serves
						your business needs. After years of working for enterprise level
						tech companies, I realized there was a huge deficit between the
						technologies big companies use and small business owners' exposure
						to said technologies. At JahkR Code, we believe small businesses or
						any should have access to new technologies and high quality
						engineering required to implement them.
					</p>
					<p>
						Any business without a website is a business missing out on
						countless opportunities for growth and a better customer experience.
						JahkR Code is the optimal solution for modern day problems in
						technology. We build high quality web applications around any
						budget. JahkR Codes for YOU!
					</p>
				</div>
				<div className='about-button'>
					<a href='/contact'>Get Started</a>
				</div>
			</div>
		</div>
	);
};

export default AboutScreen;
