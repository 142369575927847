import React from 'react';
import '../css/ServicesScreen.css';

const ServicesScreen = () => {
	return (
		<div className='spacer layer1'>
			<div className='services-container'>
				<div className='services-title'>
					<img
						src='https://i.ibb.co/DgdwSsy/Jahk-R-purple.png'
						alt='JahkR Logo'
					></img>
					<h1>Services </h1>
				</div>
				<div className='services-cards'>
					<div className='service-card float-in'>
						<div className='front rwd'>
							<h2>Responsive Web Design</h2>
						</div>
						<div className='back'>
							<ul>
								<li>eCommerce</li>
								<li>Landing Page</li>
								<li>Blogs</li>
								<li>Portfolios</li>
								<li>Customer Management Software(CMS)</li>
								<li>You name it, we build it for any industry</li>
							</ul>
						</div>
					</div>
					<div className='service-card float-out'>
						<div className='front seo'>
							<h2>Search Engine Optimization (SEO)</h2>
						</div>
						<div className='back'>
							<p>
								We prepare your site for search engines, ensuring you get the
								most out web search results.
							</p>
						</div>
					</div>
					<div className='service-card float-in'>
						<div className='front dbm'>
							<h2>Database Management</h2>
						</div>
						<div className='back'>
							<p>
								If your web app requires storage for data, we design your
								database to best suit your storage needs for any data your web
								app stores. Maintenance and security is included if required.
							</p>
						</div>
					</div>
					<div className='service-card float-out'>
						<div className='front atm'>
							<h2>Automation</h2>
						</div>
						<div className='back'>
							<p>
								Tired of doing mundane redundant tasks in your work environment?
								We can automate a solution to handle those tedious tasks,
								allowing for a more productive workflow and saving time.
							</p>
						</div>
					</div>
					<div className='service-card float-in'>
						<div className='front uid'>
							<h2>UI/UX Design</h2>
						</div>
						<div className='back'>
							<p>
								Every web app is custom made to suit your unique taste. Since we
								don't use templates, our web apps are tailored to the best
								customer experience you want to have for your audience.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServicesScreen;
