import React from 'react';
import { Link } from 'react-router-dom';
import WhatsAppButton from './WhatsAppButton';

const Footer = () => {
	const closeHandler = () => {
		const container = document.querySelector('.container');
		container.classList.remove('show-nav');
	};
	return (
		<nav>
			<ul>
				<li>
					<Link onClick={closeHandler} to='/'>
						<i className='fa fa-home'></i>{' '}
						<span className='nav-text'>Home</span>
					</Link>
				</li>
				<li>
					<Link onClick={closeHandler} to='/services'>
						<i className='fa fa-code'></i>{' '}
						<span className='nav-text'>Services</span>
					</Link>
				</li>
				<li>
					<Link onClick={closeHandler} to='/portfolio'>
						<i className='fa fa-briefcase'></i>{' '}
						<span className='nav-text'>Portfolio</span>
					</Link>
				</li>
				<li>
					<Link onClick={closeHandler} to='/about'>
						<i className='fa fa-user'></i>{' '}
						<span className='nav-text'>About</span>
					</Link>
				</li>
				<li>
					<Link onClick={closeHandler} to='/contact'>
						<i className='fa fa-phone'></i>
						<span className='nav-text'>Contact</span>
					</Link>
				</li>
			</ul>
			<WhatsAppButton />
		</nav>
	);
};

export default Footer;
